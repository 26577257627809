.nextStepAnimated {
  animation: rightCenter 0.5s forwards;
  left: calc(100% + 10px);
  position: relative;
}

.backStepAnimated {
  animation: leftCenter 0.5s forwards;
}

@keyframes rightCenter {
  from {
    left: calc(100% + 10px);
  }
  to {
    left: 0px;
  }
}

@keyframes leftCenter {
  from {
    margin-left: calc(-100% - 10px);
  }
  to {
    margin-left: 0px;
  }
}

.cardCountry:hover {
  background-color: #e9edf8;
  cursor: pointer;
}

.cardSwitchOn {
  color: var(--gradient);
}
.cardSwitchOff {
  color: #1b3065;
}

.cardSwitch:before {
  position: absolute;
  content: "";
  inset: 0.0625rem;
  border-radius: 0.9375rem;

  z-index: 2;
}

.notititle {
  padding: 0.65rem 0.25rem 0.4rem 1.25rem;
  font-weight: 800;
  font-size: 25px;
  transition: transform 300ms ease;
  z-index: 5;
}

.notibody {
  padding: 0 1.25rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notiglow,
.notiborderglow {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-50%, -50%);
  background: radial-gradient(
    circle closest-side at center,
    white,
    transparent
  );
  opacity: 0;
  transition: opacity 300ms ease;
}

.notiglow {
  z-index: 3;
}

.notiborderglow {
  z-index: 1;
}

.cardSwitch:hover .notiglow {
  opacity: 0.1;
}

.cardSwitch:hover .notiborderglow {
  opacity: 0.1;
}

.note {
  color: var(--color);
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 0.9rem;
  width: 75%;
}

.cursor-pointer {
  cursor: pointer;
}

.duration-200 {
  transition-duration: 200ms;
}

.hover\:scale-125:hover {
  transform: scale(1.25);
}

.active\:scale-100:active {
  transform: scale(1);
}

.title {
  display: none;
}

.stroke-blue-300 {
  stroke: #3182ce;
}

.stroke-blue-300:hover {
  stroke: #63b3ed;
}

.stroke-blue-300:active {
  stroke: #2c5282;
}

.card-profile {
  --primary-clr: #004163;
  --dot-clr: #fff;
  --play: hsl(195, 74%, 62%);
  width: 250px;
  height: 180px;
  border-radius: 10px;
}

.card-profile {
  font-family: "Arial";
  color: #fff;
  display: grid;
  cursor: pointer;
  grid-template-rows: 50px 1fr;
}

.card-profile:hover .img-section-profile {
  transform: translateY(1em);
}

.card-desc-profile {
  border-radius: 10px;
  position: relative;
  border: 1px solid #f00;
  display: flex;
  align-items: center;
  background: var(--primary-clr);
}

.card-time-profile {
  font-size: 1.7em;
  font-weight: 500;
}

.img-section-profile {
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #026AA2;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.card-header-profile {
  display: flex;
  align-items: center;
  width: 100%;
}

.card-title-profile {
  flex: 1;
  font-size: 0.9em;
  font-weight: 500;
}

.card-menu-profile {
  display: flex;
  gap: 4px;
  margin-inline: auto;
}

.card-profile .imgCard {
  float: right;
  max-width: 100%;
  max-height: 100%;
  margin-right: 10px;
}

.card-profile .dot-profile {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--dot-clr);
}

.card-profile .recent-profile {
  line-height: 1;
  font-size: 0.8em;
}

.typing-indicator {
  width: 60px;
  height: 30px;
  position: relative;
  z-index: 4;
}

.typing-circle {
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
  background-color: #000;
  left: 15%;
  transform-origin: 50%;
  animation: typing-circle7124 0.5s alternate infinite ease;
}

@keyframes typing-circle7124 {
  0% {
    top: 20px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 8px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.typing-circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}

.typing-circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.typing-shadow {
  width: 5px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 30px;
  transform-origin: 50%;
  z-index: 3;
  left: 15%;
  filter: blur(1px);
  animation: typing-shadow046 0.5s alternate infinite ease;
}

@keyframes typing-shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }

  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.typing-shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.typing-shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.button-scroll {
  display: block;
  position: relative;
  width: 56px;
  height: 56px;
  margin: 0;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border: 0;
}

.button-scroll:before,
.button-scroll:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 7px;
}

.button-scroll:before {
  border: 4px solid #e9edf8;
  transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.button-scroll:after {
  border: 4px solid #026AA2;
  transform: scale(1.3);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

.button-scroll:hover:before,
.button-scroll:focus:before {
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.button-scroll:hover:after,
.button-scroll:focus:after {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.button-box-scroll {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.button-elem-scroll {
  display: block;
  width: 20px;
  height: 20px;
  margin: 17px 18px 0 18px;
  fill: #f0eeef;
}

.button-scroll:hover .button-box,
.button-scroll:focus .button-box {
  transition: 0.4s;
  transform: translateX(-56px);
}

.button-refresh {
  color: white;
  background-color: #002252;
  font-weight: 500;
  border-end-end-radius: 0.5rem;
  border-end-start-radius: 0.5rem;
  font-size: 1rem;
  line-height: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  text-align: center;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  border: none;
  width: 100%;
}

.button-refresh:hover {
  background-color: #003278;
}

.button-refresh svg {
  display: inline;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.75rem;
  color: white;
}

.button-refresh:focus svg {
  animation: spin_357 0.5s linear;
}

@keyframes spin_357 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
