.container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container div {
    position: absolute;
    width: 180px;
    height: 102px;
    border-radius: 20px;
  }
  
  .container div:nth-of-type(2) {
    transform: rotate(60deg)
  }
  
  .container div:nth-of-type(3) {
    transform: rotate(-60deg)
  }
  
  .container div div {
    width: 100%;
    height: 100%;
    position: relative
  }
  
  .container div div span {
    position: absolute;
    width: 5px;
    height: 0%;
    background: #fff;
    z-index: 999999;
    border-radius: 5px;
  }
  
  .h1 {
    left: 0;
    animation: load1 7.2s ease infinite;
  }
  
  .h2 {
    right: 0;
    animation: load2 7.2s ease .6s infinite;
  }
  
  .h3 {
    right: 0;
    animation: load3 7.2s ease 1.2s infinite;
  }
  
  .h4 {
    right: 0;
    animation: load4 7.2s ease 1.8s infinite;
  }
  
  .h5 {
    left: 0;
    animation: load5 7.2s ease 2.4s infinite;
  }
  
  .h6 {
    left: 0;
    animation: load6 7.2s ease 3s infinite;
  }
  
  @keyframes load1 {
    0% {
      bottom: 0;
      height: 0
    }
  
    6.944444444% {
      bottom: 0;
      height: 100%
    }
  
    50% {
      top: 0;
      height: 100%
    }
  
    59.944444433% {
      top: 0;
      height: 0
    }
  /*   91.6666667%{top:0;height:0%;} */
  }
  
  @keyframes load2 {
    0% {
      top: 0;
      height: 0
    }
  
    6.944444444% {
      top: 0;
      height: 100%
    }
  
    50% {
      bottom: 0;
      height: 100%
    }
  
    59.944444433% {
      bottom: 0;
      height: 0
    }
  /*   91.6666667%{bottom:0;height:0%} */
  }
  
  @keyframes load3 {
    0% {
      top: 0;
      height: 0
    }
  
    6.944444444% {
      top: 0;
      height: 100%
    }
  
    50% {
      bottom: 0;
      height: 100%
    }
  
    59.94444443% {
      bottom: 0;
      height: 0
    }
  /*   91.6666667%{bottom:0;height:0%;} */
  }
  
  @keyframes load4 {
    0% {
      top: 0;
      height: 0
    }
  
    6.944444444% {
      top: 0;
      height: 100%
    }
  
    50% {
      bottom: 0;
      height: 100%
    }
  
    59.94444443% {
      bottom: 0;
      height: 0
    }
  /*   91.6666667%{bottom:0;height:0%;} */
  }
  
  @keyframes load5 {
    0% {
      bottom: 0;
      height: 0
    }
  
    6.944444444% {
      bottom: 0;
      height: 100%
    }
  
    50% {
      top: 0;
      height: 100%
    }
  
    59.94444443% {
      top: 0;
      height: 0
    }
  /*   91.6666667%{top:0;height:0%;} */
  }
  
  @keyframes load6 {
    0% {
      bottom: 0;
      height: 0
    }
  
    6.944444444% {
      bottom: 0;
      height: 100%
    }
  
    50% {
      top: 0;
      height: 100%
    }
  
    59.94444443% {
      top: 0;
      height: 0
    }
  /*   91.6666667%{top:0;height:0%;} */
  }

  /*  CSS Loading TEXT   */
  
  .loader {
    color: #1b3065;
    font-family: "Poppins",sans-serif;
    font-weight: 700;
    font-size: 25px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 8px;
  }
  
  .words {
    overflow: hidden;
    margin-top: 25px;
  }
  
  .word {
    display: block;
    height: 100%;
    padding-left: 6px;
    color: #fff;
    animation: cycle-words 5s infinite;
  }
  
  @keyframes cycle-words {
    10% {
      -webkit-transform: translateY(-105%);
      transform: translateY(-105%);
    }
  
    25% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  
    35% {
      -webkit-transform: translateY(-205%);
      transform: translateY(-205%);
    }
  
    50% {
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
    }
  
    60% {
      -webkit-transform: translateY(-305%);
      transform: translateY(-305%);
    }
  
    75% {
      -webkit-transform: translateY(-300%);
      transform: translateY(-300%);
    }
  
    85% {
      -webkit-transform: translateY(-405%);
      transform: translateY(-405%);
    }
  
    100% {
      -webkit-transform: translateY(-400%);
      transform: translateY(-400%);
    }
  }
  
  .CloseAssistantBtn{
    transition: transform 0.5s;
  }
  .CloseAssistantBtn:hover {
    transform: scale(1.2);
  }

  .SendChatAssistant {
    transition: transform 0.5s;
  }

  .SendChatAssistant:hover {
    transform: scale(1.2);
  }
    
  